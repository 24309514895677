import React, {useState} from "react";

import "./CanvasMenu.css";

const CanvasMenu = () => {


const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div>
      <div className="nav-toggle" onClick={() => {
        
        !menuOpen ? document.body.classList.add('nav-open') : document.body.classList.remove('nav-open');
        setMenuOpen(!menuOpen);
        console.log(menuOpen);
      }}>
        Menu
        <div className="nav-icon"></div>
      </div>
      <div className="nav">
        <div className="main-menu">
          <h1>GET IN TOUCH</h1>
          <p>I  like coffee & squash.</p>
          <br /><br />
          
          <p>Mads Christian Sidenius</p>
          <p style={{fontWeight: 300}}>Skalhøje 26<br />9240 Nibe<br />Denmark</p>
          <p style={{fontWeight: 300}}>Mail: <a href="mailto:info@eatthemouse.dk">info@eatthemouse.dk</a><br />
          Phone: <a href="tel:28903903">(+45) 28 903 903 </a></p>
          
                  
               
                
              
            
          
        </div>
      </div>
    </div>
  );
};

export default CanvasMenu;
