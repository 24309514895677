import React, { useState, useEffect } from "react";
import { useSpring, animated, easings } from "react-spring";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import Lottie from "lottie-react";
import BobTheBlobLoader from './lottie/blob.json'
import danskeBankIMG_ from './img/jobs_logos/danskebank_grey.png';
import danskeBankIMG from './img/jobs_logos/danskebank.png';
import jyskeBankIMG_ from './img/jobs_logos/jyskebank_grey.png';
import jyskeBankIMG from './img/jobs_logos/jyskebank.png';
import aalborgKommuneIMG from './img/jobs_logos/aalborgKommune.png'
import aalborgKommuneIMG_ from './img/jobs_logos/aalborgKommune_grey.png'
import markgraphicIMG from './img/jobs_logos/markgraphic.png'
import markgraphicIMG_ from './img/jobs_logos/markgraphic_grey.png'
import aalborgDyrehospitalIMG from './img/jobs_logos/aalborgdyrehospital.png'
import aalborgDyrehospitalIMG_ from './img/jobs_logos/aalborgdyrehospital_grey.png'
import unityCardsIMG from './img/jobs_logos/unitycards.png'
import unityCardsIMG_ from './img/jobs_logos/unitycards_grey.png'
import cupetsIMG from './img/jobs_logos/cupets.png'
import cupetsIMG_ from './img/jobs_logos/cupets_grey.png'
import decIMG from './img/jobs_logos/dec.png'
import decIMG_ from './img/jobs_logos/dec_grey.png'
import kidvertisingIMG from './img/jobs_logos/kidvertising.png'
import kidvertisingIMG_ from './img/jobs_logos/kidvertising_grey.png'
import rdIMG from './img/jobs_logos/RD.png'
import rdIMG_ from './img/jobs_logos/RD_grey.png'
import tekstaIMG from './img/jobs_logos/teksta.png'
import tekstaIMG_ from './img/jobs_logos/teksta_grey.png'
import sampoIMG from './img/jobs_logos/sampobank.png'
import sampoIMG_ from './img/jobs_logos/sampobank_grey.png'
import fiveDScandinaviaIMG from './img/jobs_logos/5dscandinavia.png'
import fiveDScandinaviaIMG_ from './img/jobs_logos/5dscandinavia_grey.png' 

import "./App.css";
import OffScreenCanvasMenu from './CanvasMenu'

function Test() {
  
  // Bob
  

  const clientList = [
    {logo: danskeBankIMG, logoGrey: danskeBankIMG_, alt: 'Danske Bank Group'},
    {logo: jyskeBankIMG, logoGrey: jyskeBankIMG_, alt: 'Jyske Bank'},
    {logo: aalborgKommuneIMG, logoGrey: aalborgKommuneIMG_, alt: 'Aalborg Kommune'},
    {logo: markgraphicIMG, logoGrey: markgraphicIMG_, alt: 'MarkGraphic'},
    {logo: aalborgDyrehospitalIMG, logoGrey: aalborgDyrehospitalIMG_, alt: 'Aalborg Dyrehospital'},
    {logo: unityCardsIMG, logoGrey: unityCardsIMG_, alt: 'Aalborg Dyrehospital'},
    {logo: cupetsIMG, logoGrey: cupetsIMG_, alt: 'Cupets'},
    {logo: decIMG, logoGrey: decIMG_, alt: 'Decembermødet'},
    {logo: kidvertisingIMG, logoGrey: kidvertisingIMG_, alt: 'Decembermødet'},
    {logo: fiveDScandinaviaIMG, logoGrey: fiveDScandinaviaIMG_, alt: '5DScandinavia'},
    {logo: rdIMG, logoGrey: rdIMG_, alt: 'Realkredit Danmark'},
    {logo: tekstaIMG, logoGrey: tekstaIMG_, alt: 'Teksta Robotics'},
    {logo: sampoIMG, logoGrey: sampoIMG_, alt: 'Sampo Pank (Finland)'},

    
  ];

  
  // positioning
  const size = useWindowSize();

  const [atSpot, setAtSpot] = useState(false);

  // run this function from an event handler or an effect to execute scroll
  function logit() {
    this.scrollY > size.height * 0.7 ? setAtSpot(true) : setAtSpot(false);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  const Box = styled(animated.div)`
    z-index: 2;
    
    position: absolute;
    
    color: "#000";
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
  `;

  const [scaleBlob, setBlobSize] = useState(1);
  const [scaleBlobDelay, setBlobSizeDelay] = useState(3000);
  const [blobDuration, setBlobDuration] = useState(6000);
  const [startPage, setStartPage] = useState(false);
  const [hideLoadPage, setHideLoadPage] = useState();
  

  

  const blobEnter = useSpring({
    to: {
      transform: `scale(${scaleBlob})`,
      top: `calc(100vh/2 - 80vw/2.4)`, // size.height / 2 - 150,
      left: `calc(10vw)`, //size.width / 2 - 150,
      opacity: scaleBlob < 1 ? 0 : 1,
    },
    from: {
      transform: `scale(0)`,
      top: `calc(100vh/2 - 80vw/2.4)`, // size.height / 2 - 150,
      left: `calc(10vw)`, //size.width / 2 - 150,
      opacity: 0,
    },
    delay: scaleBlobDelay,
    config: {
      duration: blobDuration,
      easing: easings.easeOutBack,
    },
    onRest: () => {
      console.log("Blob...");
      if (scaleBlob < 1) {

        
          setStartPage(true);
          console.log("show enter page");
          setTimeout(() => {
            setHideLoadPage(true);
          }, 500);
          
        
      }
      if (startPage) {
        setHideLoadPage(true);
      } else {
        setTimeout(() => {
          setBlobSizeDelay(1000);
          setBlobDuration(3000);
          setBlobSize(0);

        }, 3500);
        
      }
    },
  });

  const loadingSign = useSpring({
    to: [
      { color: "#fff" },
      {
        color: "#fff",
        opacity: scaleBlob < 1 ? 0 : 1,
      },
    ],
    from: {
      color: "#282a2b",
      opacity: 1,
    },
    delay: 1000,
    config: {
      duration: 2000,
      easing: easings.easeOutBack,
    },
  });

  const SlideshowOne = styled(animated.div)`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #ddd;
    color: #000;
    z-index: 0;
    opacity: ${startPage ? 1 : 0};
    display: flex;
    justify-content: center;
    align-items: center;
    
  `;

  

  const WhatIDoBG = useSpring({
    to: { background: !atSpot ? "#ddd" : "#282a2b" },
    from: {
      background: "#ddd",
    },
    delay: 0,
    config: {
      duration: 3000,
      easing: easings.easeOutBack,
    },
  });

  const mobileHorizontal =
    isMobile && size.height < size.width && size.height < 650;

  const BigHeadPos = styled(animated.div)`
    width: 80vw;
    font-size: ${size.width < 900 ? "min(13vw, 140px)" : "min(11vw, 130px)"};
    font-weight: bold;
    line-height: 1;
    font-family: 'Exo', sans-serif;
    color: #df292f;
  `;

  const BigHeadSubPos = styled.div`
    display: ${size.width < 900 ? "none" : "inline-block"};  
    position: relative;
    top: -10px
    left: 10px;
    font-size: min(1.8vw, 25px);
    width: 50vw;
    line-height: 1;
    font-family: 'Exo', sans-serif;
    text-align: justify;  
    font-weight: 500;
    color: #000;
  `;

  const BigHeadSubPosSmall = styled.div`
    display: ${size.width >= 900 ? "none" : "block"};
    padding-top: 30px;
    font-size: 18px;
    width: 80vw;
    line-height: 1.2;
    font-family: 'Exo', sans-serif;
    text-align: justify;
    font-weight: 300;
    color: #000;
  `;

  // Bubble stuff

  const Bubblestyle = styled(animated.div)`
    z-index: 1 !important;
    position: absolute;
    top: ${size.height * 2.3};
    left: ${size.width - 150};
    opacity: 0;
    margin: 0;
    padding: 0;
  `;

  const BubbleAnim = useSpring({
    to: {
      opacity: hideLoadPage ? 1 : 0,
      top: !atSpot ? size.height / 2 : size.height * 1.5 - 160,
      left: !atSpot
        ? size.width - 30 * (size.width / 100) - 75
        : (size.width / 2) - 150,
    },

    from: { opacity: 0, top: size.height * 2.3, left: size.width - 300 },
    config: {
      duration: 10000,
      easing: easings.easeOutBack,
    },
  });

  const SkillsFader = useSpring({
    to: { opacity: !atSpot ? 0 : 1, color: "#fff", zIndex: 10  },
    from: { opacity: !atSpot ? 1 : 0, zIndex: 10  },
    delay: 1500,
    config: {
      duration: 2000,
      easing: easings.easeOutBack,
    },
  });

  const WhatIDo = styled(animated.div)`
    display: ${scaleBlob < 1 ? "flex" : "none"};
    z-index: 20;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    fontsize: 20;
    color: #000;
    background-color: #fff;
    flex-direction: row;
    padding-top: 0px;
    padding-bottom: 100;
    
    
  `;

  // contact

  
  // color control
  

  

  return (
    <div>
{/* ------------- Menu component */}
<div style={{display: mobileHorizontal || !startPage ? 'none' : 'block'}}>
<OffScreenCanvasMenu/>
</div>
      {/* ------------- Bubble container */}

      

      <div
        style={{
          overflow: "hidden",
          width: "100%",
          position: "relative",
          height: !hideLoadPage ? "100vh" : "200vh",
          display: mobileHorizontal ? "none" : "block",
          backgroundColor: !hideLoadPage ? "#ddd" : "rgba(0,0,0,0)",
          
        }}

        
      >
        <Bubblestyle style={BubbleAnim}>
          <div className="moveBubbleX bubblePos">
            <div className="moveBubbleY">
              <div className="bubble"></div>
            </div>
          </div>
          
        </Bubblestyle>

        {/* ------------- Page container */}
        <div style={{ width: "100vw", height: "100vh"}}>
          {/* ------------- Load page container */}

          <div
            style={{
              position: "absolute",
              width: "100vw",
              height: "100vh",
              minHeight: '-webkit-fill-available',
              display: !hideLoadPage ? "block" : "none",
              zIndex: 10,
            }}
          >
            <Box style={loadingSign}>
              <h3>LOADING</h3 >
            </Box>

            <animated.div id="child" style={blobEnter}>
            <Lottie loop={true} style={{height: '100%', width: '100%'}} animationData={BobTheBlobLoader} />
            </animated.div>
          </div>

          {/* ------------- Frontpage container */}
          <SlideshowOne style={WhatIDoBG}>
            <BigHeadPos>
              I WOULD
              <br />
              LIKE{" "}
              <BigHeadSubPos>
                ”If you are right ahead, you will never be left behind”
                <br />I am a frontend developer, with more than 25 years of
                experience, always seeking new and exciting challenges, mainly
                within ReactJS development and projects.
              </BigHeadSubPos>
              <br />
              TO WORK
              <br />
              WITH YOU
              <br />
              <BigHeadSubPosSmall>
                I am a frontend developer, with more than 25 years of
                experience, always seeking new and exciting challenges, mainly
                within ReactJS development and projects.
                <br />
                <br />
                ”If you are right ahead, you will never be left behind”
              </BigHeadSubPosSmall>
            </BigHeadPos>
          </SlideshowOne>
        </div>
        {/* ------------- Below Frontpage container
         */}
        <WhatIDo style={WhatIDoBG}>
          <animated.div style={SkillsFader}>
            <div style={{ textAlign: "center", width: "100vw", height: '300px', minWidth: 400, lineHeight: .7}}>
              <h1 style={{color: 'yellow', fontSize: "min(13vw, 100px)",  fontWeight: 700, paddingTop: 0 }}>
                WHAT I DO
              </h1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignContent: "bottom",
                  width: "100%",
                  height: "600px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{color: '#fff', fontFamily: "Exo, sans-serif", fontWeight: '300', fontSize: "min(20px, 4vw)",  width: "50%", minWidth: "400px", lineHeight: 1.4 }}
                ><span style={{fontWeight: 700}}>Frontend Development</span><br />
                  Making your company data/brand presentable using ReactJS, HTML5 & CSS3.
                  <br />
               
                  

                
                </div>
                
              </div>
              
            </div>
            
          </animated.div>
        </WhatIDo>
        
      </div>
      
                  <div style={{display: startPage && !mobileHorizontal ? "flex" : "none", flexWrap: 'wrap', width: '100vw', minHeight: '-webkit-fill-available', justifyContent: 'center', zIndex: 200, background: '#ddd', padding: 0, margin: 0}}>
                  <div style={{flexBasis: '90%', textAlign: 'center'}}>
                  <h1 style={{fontSize: "min(10vw, 100px)",  color: '#282a2b', fontWeight: 700, paddingTop: 0 }}>
                I HAVE WORKED WITH...
              </h1>
              
              <div
                  style={{color: '#000', fontFamily: "Exo, sans-serif", fontWeight: '300', fontSize: "min(20px, 4vw)",  width: "100vw", minWidth: "400px", lineHeight: 1.4, padding: 0, margin: 0 }}
                ><span style={{fontWeight: 700}}>... quite a few interesting people and organisations during the past years.</span>
                <br /><br />
                </div>

                <div style={{display: 'flex', width: '100vw', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'row', marginBottom: 100}}>
                {clientList.map((element, index) => {
                    return (<div key={index} style={{padding: 20}}>
                    <img style={{opacity: 0.8,width: '40vw', maxWidth: 180}} src={element.logoGrey} onMouseOver={e => (e.currentTarget.src = element.logo)}
                  onMouseLeave={e => (e.currentTarget.src = element.logoGrey)} alt={element.alt} />
                  </div>)
                  })}
                  </div>
             
              </div>
              
                  
                  
                  </div>  
      
      


      
      
      
      <div
        style={{
          
          display: mobileHorizontal ? "flex" : "none",
          justifyContent: "center",
          
          alignItems: "center",
          background: "#ddd",
          color: "#000",
          width: '100vw',
          minHeight: '-webkit-fill-available'
          
        }}
      >
        <div>
          <h1 style={{ color: "#000" }}>NOTHING TO SEE HERE</h1>
          <h4 style={{ color: "#666" }}>
            Please, rotate your mobile device 90 degrees in
            any direction to return to the site.
          </h4>
          </div>
       
      </div>
      
    </div>
  );
}

export default Test;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      console.log(window.innerHeight / 2);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

/*
<a id="noUnderline" href="mailto:info@eatthemouse.dk">
        <Contact style={ContactColor}>@</Contact>
      </a>



      const ContactColor = useSpring({
    to: { color: atSpot ? "#fff" : "#000" },
    from: { color: atSpot ? "#000" : "#fff" },
    delay: 0,
    config: {
      duration: 3000,
      easing: easings.easeOutBack,
    },
  });

  const Contact = styled(animated.div)`
    position: fixed;
    z-index: 100;
    font-weight: 700;
    font-size: 25px;
    top: 15px;
    right: 15px;
    color: #000;
    a {
      color: ${atSpot ? "#fff" : "#000"};
    }
    a:visited {
      color: ${atSpot ? "#fff" : "#000"};
    }
    a: hover {
      color: #888;
    }
    text-decoration: none;
  `;

  
*/